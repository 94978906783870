(function () {
	angular.module('Plania').controller('ReferenceTypeController', ['$scope', 'Repository', '$stateParams', '$timeout', 'TranslationService', '$interval', controller]);
	function controller($scope, repository, $stateParams, $timeout, translationService, $interval) {
		$scope.isUpdate = $scope.navigation.current.name === 'referenceType.edit';
		$scope.model = { Guid: $stateParams.guid };
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.referenceDatas = [];
		$scope.selectedReferenceData = null;
		$scope.isRunningReferenceDataAction = false;
		$scope.focus = {
			referenceDataId: false
		};

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		// Helper method to create a cloned hierarchy tree from flat list.
		var buildReferenceDataTree = function (referenceDatas) {
			var treeList = [];
			var referenceDataCopy = angular.copy(referenceDatas);

			referenceDataCopy.forEach(function (referenceData) {
				if (!referenceData.ReferenceDatas) referenceData.ReferenceDatas = [];

				if (referenceData.GuidParent) {
					var parentReferenceData = _.find(referenceDataCopy, { Guid: referenceData.GuidParent });
					if (parentReferenceData) {
						if (!parentReferenceData.ReferenceDatas) parentReferenceData.ReferenceDatas = [];
						parentReferenceData.ReferenceDatas.push(referenceData);
					}
				} else {
					treeList.push(referenceData);
				}
			});
			return treeList;
		};

		// Helper method to create a cloned flat list from a hierarchy.
		var buildFlatList = function (referenceDatas) {
			var flatList = [];
			var referenceDataCopy = angular.copy(referenceDatas);

			referenceDataCopy.forEach(function (referenceData) {
				flatList.push(referenceData);
				if (referenceData.ReferenceDatas) {
					if (referenceData.ReferenceDatas.length) {
						var childFlatList = buildFlatList(referenceData.ReferenceDatas);
						flatList = flatList.concat(childFlatList);
					}
					delete referenceData.ReferenceDatas;
				}
			});
			return flatList;
		};

		var getReferenceDatas = function (guidSelectedReferenceData) {
			if (!$scope.hasReadAccess('ReferenceData')) return;
			$scope.isRunningReferenceDataAction = true;
			repository.GetPaginated(repository.apiData.referenceData.url, 0, -1, { IndexPosition: "asc" }, { PropertyFilter: [{ Property: "GuidReferenceType", Operator: "=", Value: $stateParams.guid }] }).then(function (response) {
				$scope.isRunningReferenceDataAction = false;
				if (response) {
					if (guidSelectedReferenceData)
						$scope.selectedReferenceData = _.find(response.List, { Guid: guidSelectedReferenceData });

					response.List.forEach(function (referenceData) {
						referenceData.isDisabled = !!referenceData.DisabledFromDate;
					});

					// Update new list with expanded property
					if ($scope.referenceDatas.length) {
						var flatList = buildFlatList($scope.referenceDatas);
						response.List.forEach(function (referenceData) {
							var existingReferenceData = _.find(flatList, { Guid: referenceData.Guid });
							if (existingReferenceData) {
								referenceData.isExpanded = existingReferenceData.isExpanded;
							}
						});
					}

					$scope.referenceDatas = buildReferenceDataTree(response.List);
				}
			}, function (error) {
				$scope.isRunningReferenceDataAction = false;
				repository.growl(error, 'danger');
			});
		};

		var columns = [];
		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.referenceType.url, $stateParams.guid, JSON.stringify(columns)).then(function (response) {
				$scope.model = response.Data;
			}, function (error) {
				repository.growl(error, 'danger');
			});
			getReferenceDatas();
		} else {
			$scope.model.Context = "";

			$scope.$watch('model.Context', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				if (newVal === 'BuildingType') {
					$scope.model.EntityType = repository.apiData.building.prefix;
				}
				else if (newVal === 'ControlListCauseNotExecuted') {
					$scope.model.IsHierarchy = false;
					$scope.model.EntityType = repository.apiData.controlList.prefix;
				} else if (newVal === 'BusinessUnit') {
					delete $scope.model.EntityType;
				}
				else if (newVal === "DeviationClassification" || "DeviationCause") {
					$scope.model.IsHierarchy = false;
					$scope.model.EntityType = repository.apiData.deviation.prefix;
				}
			});
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('web-referenceType-update-success', 'Referansen har blitt oppdatert.') : translationService.translate('web-referenceType-create-success', 'Referansen har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.referenceType.url, $scope.model).then(success, error);
				if ($scope.selectedReferenceData !== null && !$scope.selectedReferenceData.isNew && $scope.hasEditAccess("ReferenceData"))
					$scope.updateReferenceData($scope.selectedReferenceData);
			} else {
				repository.createSingle(repository.apiData.referenceType.url, $scope.model).then(success, error);
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-referenceType-delete-title', 'Er du sikker?'),
				text: translationService.translate('web-swal-referenceType-delete-message', "Referansen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-referenceType-delete-confirm', 'Ja, fjern referansen'),
				cancelButtonText: translationService.translate('web-swal-referenceType-delete-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.referenceType.url, $scope.model.Guid).then(function (result) {
					swal.close();
					repository.growl(translationService.translate('web-referenceType-delete-success', 'Referansen har blitt slettet'), 'success');
					$scope.goBack('referenceType.list', { menuGuid: $scope.navigation.params.menuGuid });
				});
			});
		};

		$scope.onReferenceDataClick = function (referenceData) {
			$scope.selectedReferenceData = referenceData;
		};

		$scope.isReferenceDataDisabled = function (referenceData) {
			return referenceData.DisabledFromDate && new Date(referenceData.DisabledFromDate) <= new Date();
		};

		var getHighestIndexPosition = function () {
			if ($scope.referenceDatas.length === 0) return 0;

			var maxReferenceData = _.max(buildFlatList($scope.referenceDatas), function (referenceData) {
				return referenceData.IndexPosition;
			});
			return maxReferenceData ? maxReferenceData.IndexPosition : 0;
		};

		$scope.addReferenceData = function (parentReferenceData) {
			var index = getHighestIndexPosition();

			if (parentReferenceData) {
				if (parentReferenceData.ReferenceDatas.length === 0)
					index = parentReferenceData.IndexPosition;
				else {
					var highestChildReferenceData = _.max(parentReferenceData.ReferenceDatas, function (referenceData) { return referenceData.IndexPosition; });
					index = highestChildReferenceData ? highestChildReferenceData.IndexPosition : parentReferenceData.IndexPosition;
				}
			}

			var newReferenceData = {
				GuidReferenceType: $stateParams.guid,
				isNew: true,
				IndexPosition: ++index,
				parentReferenceData: parentReferenceData
			};

			if (parentReferenceData) {
				newReferenceData.GuidParent = parentReferenceData.Guid;
			}

			$scope.selectedReferenceData = newReferenceData;

			$scope.focus.referenceDataId = true;
		};

		// Shift indexing and create new referenceData
		$scope.createReferenceData = function (referenceData) {
			var flatList = buildFlatList($scope.referenceDatas);

			for (var i = 0; i < flatList.length; i++) {
				// Shift by 1 higher
				if (i >= referenceData.IndexPosition)
					flatList[i].IndexPosition = i + 1;
				else
					flatList[i].IndexPosition = i;
			}

			var dto = {
				ReferenceDatas: flatList,
				NewReferenceData: referenceData
			};

			$scope.isRunningReferenceDataAction = true;
			repository.runAction(repository.apiData.referenceType.url + $scope.model.Guid + '/updateReferenceDatas', dto).then(function (result) {
				$scope.isRunningReferenceDataAction = false;
				if (result && result.GuidCreatedReferenceData) {
					repository.growl(translationService.translate('web-referenceType-referenceData-create-success', 'Referansedataen har blitt opprettet.'), 'success');
					getReferenceDatas(result.GuidCreatedReferenceData);
				} else {
					getReferenceDatas();
				}
			}, function (error) {
				$scope.isRunningReferenceDataAction = false;
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			});
		};

		// Update specific reference data without updating the index order of the whole list.
		$scope.updateReferenceData = function (referenceData) {
			var success = function (result) {
				$scope.isRunningReferenceDataAction = false;

				repository.growl(translationService.translate('web-referenceType-referenceData-update-success', 'Referansedataen har blitt oppdatert.'), 'success');
				getReferenceDatas();
			};
			var error = function (error) {
				$scope.isRunningReferenceDataAction = false;
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if (referenceData.DisabledFromDate)
				referenceData.DisabledFromDate = new Date(referenceData.DisabledFromDate).toISOString();

			$scope.isRunningReferenceDataAction = true;
			repository.updateSingle(repository.apiData.referenceData.url, referenceData).then(success, error);
		};

		// Remove toBeDeleted referenceData from memory list, and recalculate indexing.
		$scope.deleteReferenceData = function (referenceData) {
			if (!referenceData.Guid) return;

			var flatList = buildFlatList($scope.referenceDatas);
			flatList = _.filter(flatList, function (d) { return d.Guid !== referenceData.Guid; });
			for (var i = 0; i < flatList.length; i++) {
				flatList.IndexPosition = i;
			}

			var dto = {
				ReferenceDatas: flatList,
				GuidDeleteReferenceData: referenceData.Guid
			};

			$scope.isRunningReferenceDataAction = true;
			repository.runAction(repository.apiData.referenceType.url + $scope.model.Guid + '/updateReferenceDatas', dto).then(function (result) {
				$scope.isRunningReferenceDataAction = false;
				repository.growl(translationService.translate('web-referenceType-referenceData-delete-success', 'Referansedataen har blitt slettet.'), 'success');
				$scope.selectedReferenceData = null;
				getReferenceDatas();
			}, function (error) {
				$scope.isRunningReferenceDataAction = false;
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			});
		};

		$scope.sortReferenceDatasById = function () {
			swal({
				title: translationService.translate('web-swal-referenceType-sortReferenceDatasById-title', 'Er du sikker?'),
				text: translationService.translate('web-swal-referenceType-sortReferenceDatasById-message', "Referansedata listen vil bli sortert alfabetisk på Id. Alle eksisterende endringer på rekkefølge kan bli tapt."),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-referenceType-sortReferenceDatasById-confirm', 'Ja, sorter listen'),
				cancelButtonText: translationService.translate('web-swal-referenceType-sortReferenceDatasById-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				var flatList = buildFlatList($scope.referenceDatas);
				flatList.sort(function (a, b) {
					if (a.Id < b.Id) { return -1; }
					if (a.Id > b.Id) { return 1; }
					return 0;
				});

				for (var i = 0; i < flatList.length; i++) {
					var referenceData = flatList[i];
					referenceData.IndexPosition = i;
				}

				$scope.isRunningReferenceDataAction = true;

				var dto = {
					ReferenceDatas: flatList,
				};

				repository.runAction(repository.apiData.referenceType.url + $scope.model.Guid + '/updateReferenceDatas', dto).then(function (result) {
					$scope.isRunningReferenceDataAction = false;
					repository.growl(translationService.translate('web-referenceType-referenceData-sortReferenceDatasById-success', 'Referansedata har blitt sortert på Id'), "success");
					getReferenceDatas();
					swal.close();
				}, function (error) {
					$scope.isRunningReferenceDataAction = false;
					if (typeof (error) === "string") {
						repository.growl(error, 'danger');
					} else {
						repository.growl(error.Data.Message, 'danger');
					}
					getReferenceDatas();
				});
			});
		};

		var patchHierarchy = function () {
			var referenceTypeChange = { IsHierarchy: $scope.model.IsHierarchy };
			$scope.isSaving = true;
			repository.patch(repository.apiData.referenceType.url, $scope.model.Guid, referenceTypeChange).then(function (result) {
				$scope.isSaving = false;
			}, function (error) {
				$scope.isSaving = false;
			});
		};

		$scope.onHierarchyChange = function () {
			if (!$scope.isUpdate)
				return;

			var flatList = buildFlatList($scope.referenceDatas);
			if ($scope.model.IsHierarchy === false && $scope.referenceDatas.length !== flatList.length) {
				swal({
					title: translationService.translate('web-swal-referenceType-changeHierarchy-title', 'Er du sikker?'),
					text: translationService.translate('web-swal-referenceType-changeHierarchy-message', "Referansedata listen vil bli flatet ut, og alle endringer på hierarki strukturen vil bli tapt."),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-referenceType-changeHierarchy-confirm', 'Ja, flat ut listen'),
					cancelButtonText: translationService.translate('web-swal-referenceType-changeHierarchy-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function (shouldFlatList) {
					window.onkeydown = null;
					window.onfocus = null;

					if (shouldFlatList) {
						for (var i = 0; i < flatList.length; i++) {
							var referenceData = flatList[i];
							referenceData.IndexPosition = i;
							referenceData.GuidParent = null;
						}

						$scope.isRunningReferenceDataAction = true;
						patchHierarchy();

						var dto = {
							ReferenceDatas: flatList,
						};

						repository.runAction(repository.apiData.referenceType.url + $scope.model.Guid + '/updateReferenceDatas', dto).then(function (result) {
							$scope.isRunningReferenceDataAction = false;
							repository.growl(translationService.translate('web-referenceType-referenceData-changeHierarchy-success', 'Referansedata har blitt oppdatert til en flat liste'), "success");
							getReferenceDatas();
							swal.close();
						}, function (error) {
							$scope.isRunningReferenceDataAction = false;
							if (typeof (error) === "string") {
								repository.growl(error, 'danger');
							} else {
								repository.growl(error.Data.Message, 'danger');
							}
							getReferenceDatas();
						});

					} else {
						$timeout(function () {
							$scope.model.IsHierarchy = true;
						}, 10);
					}
				});
			} else {
				patchHierarchy();
			}
		};

		var hasDuplicateIndexPositions = function (list) {
			var indexPositions = [];
			for (var i = 0; i < list.length; i++) {
				var indexPosition = list[i].IndexPosition;
				if (indexPositions.includes(indexPosition)) return true;
				indexPositions.push(indexPosition);
			}
			return false;
		};

		// Update index and parent references
		var reorderAndSave = function (startIndex, endIndex) {
			if ($scope.referenceDatas.length === 0) return;
			var flatList = buildFlatList($scope.referenceDatas);

			if (startIndex === undefined || startIndex >= flatList.length)
				startIndex = 0;

			if (endIndex === undefined || endIndex <= startIndex)
				endIndex = flatList.length - 1;

			// Cleanup all if we have any duplicates
			if (($scope.model.IsHierarchy && flatList.length !== $scope.referenceDatas.length) || hasDuplicateIndexPositions(flatList)) {
				startIndex = 0;
				endIndex = flatList.length - 1;
			}

			if (endIndex <= startIndex) return;

			var changeList = [];
			for (var i = startIndex; i <= endIndex; i++) {
				var referenceData = flatList[i];
				if (!referenceData)
					continue;

				referenceData.IndexPosition = i;
				var changeObject = { Guid: referenceData.Guid, IndexPosition: referenceData.IndexPosition };
				if ($scope.model.IsHierarchy) {
					changeObject.GuidParent = referenceData.GuidParent;
				}
				if (changeObject.GuidParent && changeObject.GuidParent === changeObject.GUID)
					changeObject.GuidParent = null;

				changeList.push(changeObject);
			}

			var dto = {
				ReferenceDatas: changeList,
			};

			if (dto.ReferenceDatas.length === 0) return;

			$scope.isRunningReferenceDataAction = true;
			repository.runAction(repository.apiData.referenceType.url + $scope.model.Guid + '/updateReferenceDatas', dto).then(function (result) {
				$scope.isRunningReferenceDataAction = false;
				repository.growl(translationService.translate('web-referenceType-referenceData-reorderAndSave-success', 'Referansedata har blitt oppdatert'), "success");
			}, function (error) {
				$scope.isRunningReferenceDataAction = false;
				getReferenceDatas();
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			});
		};

		$scope.onReferenceDataDisabledChanged = function (referenceData) {
			if (referenceData.isDisabled) {
				referenceData.DisabledFromDate = new Date().toISOString();
			} else {
				referenceData.DisabledFromDate = null;
			}
		};

		$scope.treeOptions = {
			// Code to check if destination node is valid to drop currently dragged node
			accept: function (sourceNodeScope, destNodeScope, destIndex) {
				if ($scope.model.IsHierarchy) {
					return !$scope.isRunningReferenceDataAction && !$scope.isSaving;
				} else {
					// Force to allow only on root tree.
					return destNodeScope.$parent.$type === 'uiTree' && !$scope.isRunningReferenceDataAction && !$scope.isSaving;
				}
			},
			dropped: function (event) {
				if (event.dest.nodesScope.$parent.nodropEnabled) return;
				var draggedDown = event.source.index > event.dest.index;
				var startIndex = draggedDown ? event.dest.index : event.source.index;
				var endIndex = draggedDown ? event.source.index : event.dest.index;

				// No need to update when there has been no change
				if (startIndex === endIndex) {
					// If referenceType is hierarchy, then equal indexes might mean that the item was moved into another node.
					if (!($scope.model.IsHierarchy && event.dest.nodesScope !== event.source.nodesScope))
						return;
				}

				// Set GuidParent to current parent node.
				if ($scope.model.IsHierarchy) {
					var parentModel = event.dest.nodesScope.$parent.$modelValue;
					var currentModel = event.source.nodeScope.$modelValue;
					if (parentModel && parentModel.Guid)
						currentModel.GuidParent = parentModel.Guid;
					else
						currentModel.GuidParent = null;
				}

				setTimeout(function () {
					reorderAndSave(startIndex, endIndex);
				}, 0);
			},
		};
	}
})();
