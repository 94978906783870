(function () {
	// Select component which allows selecting a single or multiple values from a list of strings.
	// This will modify ngModel to be either a single string or a JSON stringified array of strings.
	angular.module('Plania').directive('stringValueSelect', [function () {
		return {
			restrict: 'E',
			scope: {
				ngModel: '=',
				ngDisabled: '=',
				options: '=', // Array of strings
				multiple: '=',
				allowEmpty: '='
			},
			compile: function compile(element, attrs) {
				// chosen does not read the value inside multiple attribute, so we can write multiple="false", and it will still be multiple="true".
				// Fix by moving into the compile step and manually adding the attribute.
				var select = element.find('select');
				if (select) {
					if (attrs.multiple) {
						select.attr('multiple', 'true');
					}
				}
			},
			controller: ['$scope', 'TranslationService', controller],
			template: '<select chosen data-placeholder="Velg fra listen..." disable-search="true" ng-model="selected" ng-disabled="ngDisabled" ng-options="option.value as option.label for option in dropdownOptions" ng-change="modelValueChanged()"><option id="empty-{{item.PropertyName}}" ng-if="!multiple && allowEmpty" value="" pl-translate="web-plSelect-option-empty">Ikke valgt</option></select>'
		};
	}]);

	function controller($scope, translationService) {
		// Keep local value just in case multiple is changed in runtime.
		var multiple = !!$scope.multiple;

		$scope.dropdownOptions = [];
		$scope.selected = multiple ? [] : "";

		$scope.modelValueChanged = function () {
			if (multiple) {
				if (Array.isArray($scope.selected))
					$scope.ngModel = JSON.stringify($scope.selected);
			} else {
				$scope.ngModel = $scope.selected;
			}
		};

		var parseArray = function (value) {
			if (value && typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
				try {
					var array = JSON.parse($scope.ngModel);
					if (Array.isArray(array))
						return array;
				} catch (e) {
					console.error('Invalid JSON in ngModel:', $scope.ngModel);
				}
			}
			return [];
		};

		var updateSelectedValue = function () {
			if (multiple) {
				$scope.selected = parseArray($scope.ngModel);
			} else {
				$scope.selected = $scope.ngModel || "";
			}
		};

		var setValues = function () {
			var dropdownOptions = [];

			if (Array.isArray($scope.options)) {
				dropdownOptions = $scope.options.map(function (val) {
					return {
						value: val,
						label: val
					};
				});
			}

			var existingValues = [];
			if (multiple) {
				existingValues = parseArray($scope.ngModel);
			} else {
				if ($scope.ngModel)
					existingValues = [$scope.ngModel];
			}

			if (Array.isArray(existingValues)) {
				existingValues.forEach(function (exValue) {
					if (!dropdownOptions.map(function (val) { return val.value; }).includes(exValue)) {
						dropdownOptions.push({
							value: exValue,
							label: exValue + " (" + translationService.translate('web-stringValueSelect-value-deprecated', 'utdatert') + ")"
						});
					}
				});
			}

			$scope.dropdownOptions = dropdownOptions;
		};

		setValues();
		updateSelectedValue();
		$scope.$watchGroup(["options", "ngModel"], function () {
			updateSelectedValue();
			setValues();
		});
	}
})();
