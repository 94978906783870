(function () {
	var app = angular.module('Plania');
	app.directive('buildingTemplateBuildingDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate'
			},
			controller: ['$scope', 'Repository', 'TranslationService', '$rootScope', 'ngTableParams', '$modal', 'ListService', controller],
			templateUrl: 'app/buildingTemplate/views/buildingTemplateBuilding.html'
		};
	});

	function controller($scope, repository, translationService, $rootScope, ngTableParams, $modal, listService) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasEditAccess = $rootScope.hasEditAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.navigation = $rootScope.navigation;
		$scope.userInfo = $rootScope.userInfo;

		$scope.getPropertyValue = function (entity, column) {
			return listService.GetPropertyValue(entity, column);
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="fas ' + icon + ' fa-hc-fw fa-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.buildingInfo = new function () {
			var me = this;

			this.selectedGuids = [];
			this.columns = [
				{ Position: 1, Title: translationService.translate('web-estate-id'), Property: 'Estate.Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-estate-Description'), Property: 'Estate.Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-building-Id'), Property: 'Id', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-building-Description'), Property: 'Description', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-building-buildingCategory'), Property: 'BuildingCategory.Description', PropertyType: 'string' },
			];

			this.search = {
				active: false,
				focus: false
			};

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'Id': 'asc' },
					filter: { PropertyFilter: [{ Property: 'GuidTemplate', Operator: '=', Value: $scope.model.Guid }] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							repository.GetPaginated(repository.apiData.building.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);

										var guids = result.List.map(function (building) {
											return building.Guid;
										});

										me.selectedGuids = me.selectedGuids.filter(function (guid) {
											return guids.includes(guid);
										});
									},
									function (error) {
										repository.showError(error);
									});
						}
					});
			};

			this.addBuildings = function () {
				if (!$scope.userInfo.isSystemAdministrator) return;
				$modal.open({
					templateUrl: 'app/common/views/multipleSelectModal.html',
					controller: 'MultipleSelectModalController',
					size: "lg",
					resolve: {
						modalParams: function () {
							return {
								title: translationService.translate('web-buildingTemplate-addBuilding-title', 'Legg til Byggning'),
								columns: [
									{ Title: translationService.translate('web-estate-id'), Property: 'Estate.Id' },
									{ Title: translationService.translate('web-estate-Description'), Property: 'Estate.Description' },
									{ Title: translationService.translate('web-building-Id'), Property: 'Id' },
									{ Title: translationService.translate('web-building-Description'), Property: 'Description' },
									{ Title: translationService.translate('web-building-buildingCategory'), Property: 'BuildingCategory.Description' },
									{ Title: translationService.translate('web-building-dataOwner'), Property: 'DataOwner.Id' },
								],
								url: repository.apiData.building.url,
								sorting: { Id: 'asc' },
								filter: {
									FilterModel: {
										Condition: "and",
										Rules: [{
											Property: "GuidTemplate",
											Operator: "=",
											Value: null,
										}, {
											Property: "IsTemplate",
											Operator: "=",
											Value: false,
										}]
									}
								}
							};
						}
					}
				}).result.then(function (selectedGuids) {
					if (!selectedGuids) return;

					var changeList = [];
					selectedGuids.forEach(function (guid) {
						changeList.push({ Guid: guid, GuidTemplate: $scope.model.Guid });
					});

					repository.patch(repository.apiData.building.url, null, changeList).then(function (response) {
						var success = _.every(response, function (response) {
							return response.IsSuccess;
						});

						if (success)
							repository.growl(translationService.translate('web-buildingTemplate-addBuildings-success', 'Byggene har blitt lagt til byggmal.'), "success");
						else
							repository.growl(translationService.translate('web-buildingTemplate-addBuildings-failed', 'En eller flere bygg ble ikke lagt til byggmal'), "danger");
						me.table.reload();
					}, function (error) {
						me.table.reload();
						repository.showError(error);
					});
				});
			};

			this.selectItem = function (item) {
				if (me.selectedGuids.includes(item.Guid)) {
					me.selectedGuids = me.selectedGuids.filter(function (guid) {
						return guid !== item.Guid;
					});
				} else {
					me.selectedGuids.push(item.Guid);
				}
			};

			this.isItemSelected = function (item) {
				return me.selectedGuids.includes(item.Guid);
			};

			this.synchronize = function (guidBuildings) {
				if (!$scope.userInfo.isSystemAdministrator) return;
				$modal.open({
					templateUrl: 'app/buildingTemplate/views/synchronizeBuildingsForTemplateModal.html',
					controller: 'SynchronizeBuildingsForTemplateModalController',
					size: "lg",
					resolve: {
						params: function () {
							return {
								guidTemplate: $scope.model.Guid,
								guidBuildings: guidBuildings,
								count: guidBuildings ? guidBuildings.length : me.table.settings().total
							};
						}
					}
				}).result.then(function () {
					me.table.reload();
				}, function () {
					me.table.reload();
				});
			};

			this.unlink = function (guidBuildings) {
				if (!$scope.userInfo.isSystemAdministrator) return;
				swal({
					title: translationService.translate('web-buildingTemplate-buildings-unlink-swalTitle', 'Er du sikker?'),
					text: translationService.translate('web-buildingTemplate-buildings-unlink-swalMessage', 'Dette vil koble bort referansen for valgt bygg bort ifra gjeldende mal. Dette vil også frikoble anlegg, dokument og periodiske rutiner fra sine maler.'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-buildingTemplate-buildings-unlink-confirm', 'Ja, koble fra mal'),
					cancelButtonText: translationService.translate('web-buildingTemplate-buildings-unlink-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.runAction(repository.apiData.building.url + "unlink/", guidBuildings ? { GuidBuildings: guidBuildings } : null).then(function (response) {
						swal.close();
						me.table.reload();
					}, function (error) {
						repository.showError(error);
						swal.close();
						me.table.reload();
					});
				});
			};


			this.contextMenuOptions = function (item) {
				var options = [];
				options.push(getMenuLink($rootScope.navigation.href('building.edit', { guid: item.Guid }), translationService.translate('web-buildingTemplate-buildings-contextMenu-goToBuilding', 'Gå til bygg')));

				if ($scope.userInfo.isSystemAdministrator) {
					if (me.selectedGuids.length) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-buildingTemplate-buildings-contextMenu-synchronizeSelectedBuildings', 'Synkroniser valgte bygg') + '</a >',
							click: function () {
								me.synchronize(me.selectedGuids);
							}
						});
					} else {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-buildingTemplate-buildings-contextMenu-synchronizeBuilding', 'Synkroniser bygg') + '</a >',
							click: function () {
								me.synchronize([item.Guid]);
							}
						});
					}

					if (me.selectedGuids.length) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-buildingTemplate-buildings-contextMenu-unlinkSelectedBuildings', 'Koble valgte bygg bort fra mal') + '</a >',
							click: function () {
								me.unlink(me.selectedGuids);
							}
						});
					} else {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-buildingTemplate-buildings-contextMenu-unlinkBuilding', 'Koble bygg fra mal') + '</a >',
							click: function () {
								me.unlink([item.Guid]);
							}
						});
					}
				}

				return options;
			};

			me.initTable();
		}();
	}
})();
