(function () {
    // Custom select to allow keeping of deprecated values.
    // This will also convert between an internal value and external value (Ex multiple: ExternalValue is a string, InternalValue is an array)
    angular.module('Plania').directive('dynamicPropertySelect', [function () {
        return {
            restrict: 'E',
            require: '^dynamicProperties',
            scope: {
                ngModel: '=',
                ngDisabled: '=',
                item: '=',
                multiple: '='
            },
            compile: function compile(element, attrs) {
                // chosen does not read the value inside multiple attribute, so we can write multiple="false", and it will still be multiple="true".
                // Fix by moving into the compile step and manually adding the attribute.
				var select = element.find('string-value-select');

                if (select) {
                    if (attrs.multiple) {
                        select.attr('multiple', 'true');
                    }
                }
            },
            controller: ['$scope', controller],
            template: '<string-value-select class="form-control overflow-visible" ng-model="ngModel" ng-disabled="ngDisabled" options="values" allow-empty="!item.Mandatory"></string-value-select>'
        };
    }]);

    function controller($scope) {
        $scope.values = [];
        var setValues = function () {
            var values = [];
            if ($scope.item.NamedSelectionOptions) {
                values = $scope.item.NamedSelectionOptions.map(function (val) {
                    return val;
                });
            }

            $scope.values = values;
        };

        setValues();

        $scope.$watchGroup(["item.NamedSelectionOptions"], function () {
            setValues();
        });
    }
})();
