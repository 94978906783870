// View component to display the data used from plSelect directive.
(function () {
	angular.module('Plania').directive('stringValueSelectView', [function () {
		return {
			restrict: 'E',
			scope: {
				ngModel: '=',
				multiple: '='
			},
			controller: ['$scope', controller],
			templateUrl: "app/common/directives/views/stringValueSelectView.html"
		};
	}]);

	function controller($scope) {
		$scope.values = [];
		// Keep local value just in case multiple is changed in runtime.
		$scope.isMultiple = $scope.multiple;

		if ($scope.isMultiple) {
			var updateValue = function () {
				var values = [];

				if ($scope.ngModel && typeof $scope.ngModel === 'string' && $scope.ngModel.startsWith('[') && $scope.ngModel.endsWith(']')) {
					try {
						values = JSON.parse($scope.ngModel);
					} catch (e) {
						console.error('Invalid JSON in ngModel:', $scope.ngModel);
						values = [];
					}
				} else if ($scope.ngModel) {
					values = [$scope.ngModel];
				}

				$scope.values = values;
			};

			$scope.$watchGroup(["options", "ngModel"], function () {
				updateValue();
			});
		}
	}
})();
